module.exports = {
  name: "Spa",
  sections: [
    {
      name: "Body Massage Treatment",
      images: [
        "/assets/spa/spa-services/body_treatment_section_2_1.jpg",
        "/assets/spa/spa-services/body_treatment_section_2_2_.jpg",
      ],
      items: [
        {
          name: "Swedish Massage",
          description:
            "This is a popular treatment with kneading and hacking techniques combined with medium and firm pressure to help release stiff muscles and poor circulation",
          options: [
            {
              description: "60 Minutes",
              price: 464000,
            },
          ],
        },
        {
          name: "Laluna Signature Therapy",
          description:
            "A combination of deep tissue massage and traditional massage techniques where pressure level is tailored to the individual's needs",
          options: [
            {
              description: "60 Minutes",
              price: 556000,
            },
          ],
        },
        {
          name: "Healing Stone Therapy",
          description:
            "Heated basalt stones are placed in specific locations of the individual's body to aid in deep tissue and muscle relaxation",
          options: [
            {
              description: "75 Minutes",
              price: 580000,
            },
          ],
        },
        {
          name: "Foot Reflexology",
          description:
            "The massage therapy that involves applying pressure to specific points on the foot. It is designed to reduce pain and improve overall health",
          options: [
            {
              description: "60 Minutes",
              price: 556000,
            },
          ],
        },
        {
          name: "Shiatsu Therapy",
          description:
            "Shiatsu therapy is a medium pressure massage applied with the fingers and palms at the acupuncture points of the body to increase blood circulation",
          options: [
            {
              description: "60 Minutes",
              price: 504000,
            },
          ],
        },
      ],
    },
    {
      images: [
        "/assets/spa/spa-services/body_treatment_section_3_1.jpg",
        "/assets/spa/spa-services/body_treatment_section_3_2.jpg",
      ],
      items: [
        {
          name: "Hot Herbal Therapy",
          description:
            "The massage therapist with apply heated essential oils to warm the body and expel the pores. The headed essential oils relax the individual's muscles and relieve pain",
          options: [
            {
              description: "60 Minutes",
              price: 520000,
            },
            {
              description: "75 Minutes",
              price: 600000,
            },
            {
              description: "90 Minutes",
              price: 664000,
            },
          ],
        },
        {
          name: "Thai Massage",
          description:
            "The therapy originated from Thailand. It is a traditional healing system combine acupressure and assisted yoga postures to balance and release your body",
          options: [
            {
              description: "60 Minutes",
              price: 520000,
            },
            {
              description: "90 Minutes",
              price: 664000,
            },
          ],
        },
        {
          name: "Pregnancy Massage",
          description:
            "With this treatment, you can increase relaxation, reduce stress, and improve blood circulation to help with various symptoms caused by pregnancy including insomnia, edema, headache, and high blood pressure",
          options: [
            {
              description: "60 Minutes",
              price: 496000,
            },
          ],
        },
        {
          name: "Child Massage",
          description:
            "A massage treat for your child to relax and release stiff muscles",
          options: [
            {
              description: "30 Minutes",
              price: 350000,
            },
          ],
        },
      ],
    },
    {
      images: [
        "/assets/spa/spa-services/express_treatment_1.jpg",
        "/assets/spa/spa-services/express_treatment_2.jpg",
        "/assets/spa/spa-services/express_treatment_3.jpg",
        "/assets/spa/spa-services/express_treatment_4.jpg",
      ],
      items: [
        {
          name: "Hair Washing",
          description:
            "Hair washing with light head, neck, and shoulder massage",
          options: [
            {
              description: "45 Minutes",
              price: 450000,
            },
          ],
        },
        {
          name: "Indian Head & Hands Massage",
          options: [
            {
              description: "30 Minutes",
              price: 350000,
            },
            {
              description: "60 Minutes",
              price: 504000,
            },
          ],
        },
        {
          name: "Back, Neck & Shoulder Therapy",
          options: [
            {
              description: "30 Minutes",
              price: 400000,
            },
            {
              description: "60 Minutes",
              price: 504000,
            },
          ],
        },
        {
          name: "Foot Reflexology",
          options: [
            {
              description: "30 Minutes",
              price: 350000,
            },
            {
              description: "60 Minutes",
              price: 556000,
            },
          ],
        },
        {
          name: "Leg Therapy",
          options: [
            {
              description: "30 Minutes",
              price: 350000,
            },
            {
              description: "60 Minutes",
              price: 556000,
            },
          ],
        },
        {
          name: "Abdoment Therapy",
          options: [
            {
              description: "30 Minutes",
              price: 350000,
            },
          ],
        },
        {
          name: "Eye Relaxing Therapy",
          options: [
            {
              description: "30 Minutes",
              price: 350000,
            },
          ],
        },
        {
          name: "Sauna/Steam Bath",
          description: "",
          options: [
            {
              description: "30 Minutes",
              price: 350000,
            },
          ],
        },
      ],
      name: "Express Treatment",
    },
    {
      name: "Body Exfoliate",
      images: [
        "/assets/spa/spa-services/body_exfoliate_1.jpg",
        "/assets/spa/spa-services/body_exfoliate_2.jpg",
      ],
      items: [
        {
          name: "Aromatic Salt Scrub",
          options: [
            {
              description: "60 Minutes",
              price: 520000,
            },
          ],
        },
        {
          name: "Rice & Fresh Milk Scrub",
          options: [
            {
              description: "60 Minutes",
              price: 520000,
            },
          ],
        },
        {
          name: "Oriental Honey, Milk & Black Sesame Scrub",
          options: [
            {
              description: "60 Minutes",
              price: 552000,
            },
          ],
        },
        {
          name: "Green Tea Scrub",
          options: [
            {
              description: "60 Minutes",
              price: 520000,
            },
          ],
        },
        {
          name: "Honey & Coffee Scrub",
          options: [
            {
              description: "60 Minutes",
              price: 520000,
            },
          ],
        },
      ],
    },
    {
      name: "Body Wrap",
      images: [
        "/assets/spa/spa-services/body_exfoliate_1.jpg",
        "/assets/spa/spa-services/body_exfoliate_2.jpg",
      ],
      items: [
        {
          name: "Aloe Vera-Cucumber",
          options: [
            {
              description: "60 Minutes",
              price: 472000,
            },
          ],
        },
        {
          name: "Honey-Yogurt",
          description: "",
          options: [
            {
              description: "60 Minutes",
              price: 472000,
            },
          ],
        },
        {
          name: "Rice Bran with Yogurt",
          description: "",
          options: [
            {
              description: "60 Minutes",
              price: 472000,
            },
          ],
        },
        {
          name: "Vietnamese Herbs & Dead Sea Mud",
          description: "",
          options: [
            {
              description: "60 Minutes",
              price: 472000,
            },
          ],
        },
      ],
    },
    {
      name: "Facial Options",
      images: [
        "/assets/spa/spa-services/facial_1.jpg",
        "/assets/spa/spa-services/facial_2.jpg",
        "/assets/spa/spa-services/facial_3.jpg",
      ],
      items: [
        {
          name: "Laluna Signature Facial",
          options: [
            {
              description: "60 Minutes",
              price: 520000,
            },
          ],
        },
        {
          name: "Age Defying Vitamin C Facial Add-on",
          options: [
            {
              description: "60 Minutes",
              price: 520000,
            },
          ],
        },
        {
          name: "Purifying Facial with Oatmeal Mask",
          options: [
            {
              description: "60 Minutes",
              price: 588000,
            },
          ],
        },
        {
          name: "Detoxifying with Green Tea Mask",
          options: [
            {
              description: "60 Minutes",
              price: 536000,
            },
          ],
        },
      ],
    },
    {
      name: "Packages",
      images: [
        "/assets/spa/spa-services/packages_section_1_1.jpg",
        "/assets/spa/spa-services/packages_section_1_2.jpg",
        "/assets/spa/spa-services/packages_section_1_3.jpg",
      ],
      items: [
        {
          name

: "Laluna Refreshment",
          options: [
            {
              description: "120 Minutes",
              price: 960000,
            },
          ],
          description: ["60 Minutes Body Massage", "60 Minutes Facial Care"],
        },
        {
          name: "Laluna Honeymoon Package",
          options: [
            {
              description: "180 Minutes",
              price: 2600000,
            },
          ],
          description: [
            "Package for 2 guests",
            "45 Minutes Body Scrub",
            "60 Minutes Body Massage",
            "60 Minutes Facial Care",
            "15 Minutes Aroma Sauna & Steam Bath",
          ],
        },
        {
          name: "Renew Your Skin",
          options: [
            {
              description: "150 Minutes",
              price: 1280000,
            },
          ],
          description: [
            "45 Minutes Body Scrub",
            "45 Minutes Body Wrap",
            "60 Minutes Body Massage",
          ],
        },
      ],
    },
    {
      images: [
        "/assets/spa/spa-services/packages_section_3_1.jpg",
        "/assets/spa/spa-services/packages_section_3_2.jpg",
      ],
      items: [
        {
          name: "Holistic Package",
          options: [
            {
              description: "150 Minutes",
              price: 1280000,
            },
          ],
          description: [
            "30 Minutes Foot Massage",
            "60 Minutes Body Massage",
            "60 Minutes Facial Care",
          ],
        },
        {
          name: "Care For Pregnancy",
          description: [
            "30 Minutes Eye Relaxing Therapy",
            "60 Minutes Vietnamese Herbs & Dead Sea Mud Skin Treatment",
            "60 Minutes Pregnancy Massage",
          ],
          options: [
            {
              description: "150 Minutes",
              price: 1200000,
            },
          ],
        },
        {
          name: "Laluna Consciousness",
          description:
            "We give you the freedom to design your own journey by choosing three 60 minutes Treatments of your own choice from own Spa menu",
          options: [
            {
              description: "180 Minutes",
              price: 1400000,
            },
          ],
        },
      ],
    },
    {
      images: [
        "/assets/spa/spa-services/manicure_1.jpg",
        "/assets/spa/spa-services/manicure_2.jpg",
        "/assets/spa/spa-services/manicure_3.jpg",
      ],
      items: [
        {
          name: "Basic Manicure",
          description:
            "Nail trimming, shaping, hand soak, cuticle grooming and nail buffing",
          options: [
            {
              description: "30 Minutes",
              price: 200000,
            },
          ],
        },
        {
          name: "Laluna Manicure",
          description:
            "Nail trimming, shaping, hand soak, hand scrub, cuticle grooming, nail buffing, hand massage and nail polish",
          options: [
            {
              description: "30 Minutes",
              price: 250000,
            },
            {
              description: "60 Minutes",
              price: 296000,
            },
          ],
        },
        {
          name: "Manicure for Children",
          options: [
            {
              description: "30 Minutes",
              price: 180000,
            },
          ],
        },
      ],
      name: "Manicure Nail Services",
    },
    {
      name: "Pedicure Nail Services",
      images: [
        "/assets/spa/spa-services/pedicure_1.jpg",
        "/assets/spa/spa-services/pedicure_2.jpg",
        "/assets/spa/spa-services/pedicure_3.jpg",
      ],
      items: [
        {
          name: "Basic Pedicure",
          description:
            "Nail trimming, shaping, foot soak, cuticle grooming, nail buffing",
          options: [
            {
              description: "30 Minutes",
              price: 200000,
            },
          ],
        },
        {
          name: "Laluna Pedicure",
          description:
            "Nail trimming, shaping, foot soak, foot scrub, heel scrub cuticle grooming, nail buffing, foot massage and nail polish.",
          options: [
            {
              description: "60 Minutes",
              price: 296000,
            },
          ],
        },
        {
          name: "Shellac Removal",
          description: "",
          options: [
            {
              description: "30 Minutes",
              price: 250000,
            },
          ],
        },
        {
          name: "Pedicure for Children",
          options: [
            {
              description: "30 Minutes",
              price: 180000,
            },
          ],
        },
        {
          name: "Heel Scrub",
          description: "Remove dead cell renew your heels & moisturize",
          options: [
            {
              description: "40 Minutes",
              price: 200000,
            },
          ],
        },
      ],
    },
  ],
};
