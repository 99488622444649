import React from "react";
import styled, { css } from "styled-components";
import { NumericFormat } from "react-number-format";
import { MenuOptionDef } from "./Types";
import { DesktopOnly, MobileOnly } from "../StandardComponents";

const MenuItemContainer = styled.div`
  //background-color: pink;
  width: 100%;
  margin-bottom: 15px;
`;

/*const MenuNamePriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;*/

const MenuNameAndOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MenuNameAndOptionsContainerMobile = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: center;
  & > * {
    margin-top: 10px;
  }
`;

const MenuOptionsContainer = styled.div``;

const MenuOptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 150%;
`;

const MenuOptionDescriptionContainer = styled.div`
  margin-right: 20px;
`;

const MenuItemName = styled.div`
  font-size: 120%;
  font-weight: 400;
`;

const MenuItemDescription = styled.div`
  font-size: 75%;
  width: 80%;
  text-align: left;
  font-style: italic;
`;

const MenuItemDescriptionCenter = styled.div`
  font-size: 75%;
  width: 80%;
  text-align: center;
  font-style: italic;
`;

const MenuItemPrice = styled.div`
  &::after {
    content: " VND";
  }
`;

interface MenuItemInterface {
  entryName: string;
  //entryPrice: number;
  entryOptions: Array<MenuOptionDef>;
  entryDescription?: string | Array<string>;
}

export const MenuEntryDescriptionCompositeMobile: React.FC<{
  descriptions: string | Array<string>;
}> = (props) => {
  if (Array.isArray(props.descriptions)) {
    return (
      <MenuItemDescription>
        <ul>
          {props.descriptions.map((descItem, index) => (
            <li>{descItem}</li>
          ))}
        </ul>
      </MenuItemDescription>
    );
  } else {
    return (
      <MenuItemDescriptionCenter>
        {props.descriptions}
      </MenuItemDescriptionCenter>
    );
  }
};
export const MenuEntryMobile: React.FC<MenuItemInterface> = (props) => (
  <MenuItemContainer>
    <MenuNameAndOptionsContainerMobile>
      <MenuItemName>{props.entryName}</MenuItemName>
      {props.entryDescription && <MenuEntryDescriptionCompositeMobile descriptions={props.entryDescription} />}
      <MenuOptionsContainer>
        {props.entryOptions &&
          props.entryOptions.length > 0 &&
          props.entryOptions.map((option, index) => (
            <MenuOptionContainer key={index}>
              {option.description && (
                <MenuOptionDescriptionContainer>
                  {option.description}
                </MenuOptionDescriptionContainer>
              )}
              <MenuItemPrice>
                <NumericFormat
                  value={option.price}
                  displayType={"text"}
                  thousandSeparator={true}
                ></NumericFormat>
              </MenuItemPrice>
            </MenuOptionContainer>
          ))}
      </MenuOptionsContainer>
    </MenuNameAndOptionsContainerMobile>
  </MenuItemContainer>
);

export const MenuEntryDeskop: React.FC<MenuItemInterface> = (props) => (
  <MenuItemContainer>
    <MenuNameAndOptionsContainer>
      <MenuItemName>{props.entryName}</MenuItemName>
      <MenuOptionsContainer>
        {props.entryOptions &&
          props.entryOptions.length > 0 &&
          props.entryOptions.map((option, index) => (
            <MenuOptionContainer key={index}>
              {option.description && (
                <MenuOptionDescriptionContainer>
                  {option.description}
                </MenuOptionDescriptionContainer>
              )}
              <MenuItemPrice>
                <NumericFormat
                  value={option.price}
                  displayType={"text"}
                  thousandSeparator={true}
                ></NumericFormat>
              </MenuItemPrice>
            </MenuOptionContainer>
          ))}
      </MenuOptionsContainer>
    </MenuNameAndOptionsContainer>
    <MenuItemDescription>
      {(() => {
        if (Array.isArray(props.entryDescription)) {
          return (
            <ul>
              {props.entryDescription.map((descItem, index) => (
                <li>{descItem}</li>
              ))}
            </ul>
          );
        }
        return props.entryDescription;
      })()}
    </MenuItemDescription>
  </MenuItemContainer>
);

export const MenuEntry: React.FC<MenuItemInterface> = (props) => (
  <>
    <DesktopOnly>
      <MenuEntryDeskop {...props} />
    </DesktopOnly>
    <MobileOnly>
      <MenuEntryMobile {...props} />
    </MobileOnly>
  </>
);
